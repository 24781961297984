<template>
  <div class="w-full h-full">
    <!-- <Navbar /> -->
    <MapboxMap
      ref="map"
      mapId="map"
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :longitude="camera.longitude"
      :latitude="camera.latitude"
      :zoom="camera.zoom"
      :pitch="camera.pitch"
      :bearing="camera.bearing"
      :bounds="bounds"
      :padding="padding"
      :minZoom="minZoom"
      :maxZoom="maxZoom"
      :cameraOptions="{ animate: false }"
      :fitBoundsOptions="{ animate: false }"
      logoPosition="bottom-right"
      @camera="onCameraChange($event)"
      @bounds="onBoundsChange($event)"
      @click="onClick($event)"
      @mousemove="onHover($event)"
      @mouseout="onLeave($event)"
      @touchstart="onTouch($event)"
      @touchend="onTouch($event)"
      @load="onLoad($event)"
      @idle="onIdle($event)"
      @resize="onResize($event)"
    >
      <FlightLayer @loaded="onFlightsLoad($event)" />
      <StationLayer @loaded="onStationsLoad($event)" />
      <PathLayer v-if="isSelected && selected.layer === 'flights'" />
      <PositionLayer v-if="isSelected && selected.layer === 'flights'" />
      <ZoneLayer
        v-if="isSelected && showZone && selected.layer === 'stations'"
      />
      <GeoTooltip v-if="isHovered && !touch" :coordinates="coordinates">
        <FlightTooltip
          v-if="getHovered.layer === 'flights'"
          :hovered="getHovered"
        />
        <StationTooltip
          v-if="getHovered.layer === 'stations'"
          :hovered="getHovered"
        />
      </GeoTooltip>
      <SideBar v-if="isSelected" />
      <SearchBar />
      <Modal v-if="isModalOpen" />
      <div class="ctrl-container">
        <NavigationControl :visualizePitch="true" class="m-2 rounded" />
        <GeolocateControl
          @geolocate="onGeolocate($event)"
          :trackUserLocation="true"
          @trackuserlocationstart="onTrack($event)"
          @trackuserlocationend="onTrack($event)"
          class="m-2 rounded"
        />
        <FullscreenControl class="m-2 rounded" />
      </div>
      <MapboxImage
        id="HLCPT"
        :src="helicopter"
        :options="{ sdf: true, pixelRatio: 8 }"
      />
      <MapboxImage id="pulse" :src="pulse" />
      <MapboxMarker v-if="track && geolocation" :lngLat="geolocation">
        <GeolocateIcon class="text-blue-500" />
      </MapboxMarker>
    </MapboxMap>
  </div>
</template>

<script>
// import Navbar from "./Navbar";
import FlightLayer from "./FlightLayer";
import PathLayer from "./PathLayer";
import PositionLayer from "./PositionLayer";
import StationLayer from "./StationLayer";
import ZoneLayer from "./ZoneLayer";
import GeoTooltip from "./GeoTooltip";
import FlightTooltip from "./FlightTooltip";
import StationTooltip from "./StationTooltip";
import SearchBar from "./SearchBar";
import SideBar from "./SideBar";
import Modal from "./Modal";
import style from "../assets/styles/outdoors.json";
import helicopter from "../assets/animations/helicopter";
import pulse from "../assets/animations/pulse";
import GeolocateIcon from "../assets/icons/geolocate.svg";

import { mapState, mapGetters, mapMutations } from "vuex";
import { sortByDistance } from "../utils/geo";

export default {
  name: "Map",
  components: {
    // Navbar,
    FlightLayer,
    StationLayer,
    PathLayer,
    ZoneLayer,
    PositionLayer,
    GeoTooltip,
    FlightTooltip,
    StationTooltip,
    SearchBar,
    SideBar,
    Modal,
    GeolocateIcon,
  },
  computed: {
    ...mapState([
      "camera",
      "bounds",
      "selected",
      "hovered",
      "padding",
      "bbox",
      "flights",
      "stations",
      "isModalOpen",
      "showZone",
    ]),
    ...mapGetters(["isSelected", "isHovered", "getSelected", "getHovered"]),
    coordinates() {
      return this.map.project(this.getHovered.coordinates);
    },
  },
  data() {
    return {
      helicopter,
      pulse,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: style,
      loaded: false,
      minZoom: 0,
      maxZoom: 22,
      // list of interactive layers for feature querying
      interactive: [
        "flights",
        "label-flight",
        "label-ground",
        "ground",
        "stations",
      ],
      map: null,
      touch: false,
      geolocation: undefined,
      track: false,
      styles: [
        { title: "Outdoors", uri: require("../assets/styles/outdoors.json") },
        { title: "Dark", uri: require("../assets/styles/dark.json") },
      ],
    };
  },
  mounted() {
    if (this.$route.name === "coords") {
      let { zoom, latitude, longitude } = this.$route.params;
      zoom >= this.minZoom && zoom <= this.maxZoom
        ? (zoom = parseInt(zoom))
        : (zoom = this.camera.zoom);
      latitude >= -90 && latitude <= 90
        ? (latitude = parseInt(latitude))
        : (latitude = this.camera.latitude);
      longitude >= -180 && longitude <= 180
        ? (longitude = parseInt(longitude))
        : (longitude = this.camera.longitude);
      this.setCamera({
        zoom,
        latitude,
        longitude,
      });
    } else if (this.$route.name === "map") {
      this.$router.replace({
        path: `/${this.camera.zoom.toFixed(2)}/${this.camera.latitude.toFixed(
          2
        )}/${this.camera.longitude.toFixed(2)}`,
      });
    }
  },
  methods: {
    ...mapMutations([
      "setCamera",
      "setScreen",
      "setBbox",
      "setSelected",
      "setHovered",
      "setPulse",
      "removeSelected",
      "removePulse",
      "removeFilter",
      "removeHovered",
      "toggleModal",
    ]),
    onLoad() {
      this.map = this.$refs.map.map;
      let {
        clientWidth: width,
        clientHeight: height,
      } = this.$refs.map.map.getCanvas();
      this.setScreen({ width, height });
    },
    onFlightsLoad() {
      if (this.$route.params.id && this.$route.name === "flights") {
        if (this.flights[this.$route.params.id]) {
          this.setSelected({
            id: this.$route.params.id,
            layer: this.$route.name,
          });
          this.setPulse(this.getSelected);
        } else {
          this.toggleModal(true);
        }
      }
    },
    onStationsLoad() {
      if (this.$route.params.id && this.$route.name === "stations") {
        if (this.stations[this.$route.params.id]) {
          this.setSelected({
            id: this.$route.params.id,
            layer: this.$route.name,
          });
          this.setPulse(this.getSelected);
        } else {
          this.toggleModal(true);
        }
      }
    },
    onResize() {
      let { clientWidth: width, clientHeight: height } = this.map.getCanvas();
      this.setScreen({ width, height });
    },
    onIdle() {
      this.loaded = true;
    },
    onClick(event) {
      if (this.loaded) {
        // Request the list of features under the click
        let features = this.map.queryRenderedFeatures(event.point, {
          layers: this.interactive,
        });
        if (features.length > 0) {
          // Get the geographic coordinates of the click
          let point = [event.lngLat.lng, event.lngLat.lat];
          // Return the closest feature to the coordinates of the click
          let result = sortByDistance(features, point)[0];
          // Change the state of the clicked feature and add it to the store
          this.setSelected({ id: result.id, layer: result.properties.layer });
          this.$router.replace({
            path: `/${result.properties.layer}/${result.id}`,
          });
          this.setPulse(this.getSelected);
        } else {
          this.removeFilter();
          this.removeSelected();
          if (this.$route.name !== "coords") {
            this.$router.replace({
              path: `/${this.camera.zoom.toFixed(
                2
              )}/${this.camera.latitude.toFixed(
                2
              )}/${this.camera.longitude.toFixed(2)}`,
            });
          }
          this.removePulse();
        }
      }
    },
    /**
     * Get the hovered feature change it's state on the map and store it in vuex
     * @param {Object} event Event emitted by the map on mouse move
     */
    onHover(event) {
      // Wait for the layer to be loaded
      if (this.loaded) {
        // Request the list of features under the mouse
        let features = this.map.queryRenderedFeatures(event.point, {
          layers: this.interactive,
        });
        if (features.length > 0) {
          // Get the geographic coordinates of the mouse
          let point = [event.lngLat.lng, event.lngLat.lat];
          // Change the pointer style
          this.map.getCanvas().style.cursor = "pointer";
          // Return the closest feature to the coordinates of the click
          let result = sortByDistance(features, point)[0];
          // Change the state of the clicked feature and add it to the store
          if (!this.isHovered || result.id !== this.hovered.id) {
            this.setHovered({
              id: result.id,
            });
          }
        } else {
          this.map.getCanvas().style.cursor = "grab";
          this.removeHovered();
        }
      }
    },
    /**
     * Remove the hover if the mouse leave the canvas
     */
    onLeave() {
      if (this.isHovered) {
        this.map.getCanvas().style.cursor = "grab";
        this.removeHovered();
      }
    },
    onCameraChange(event) {
      this.setCamera(event);
      if (this.$route.name === "map" || this.$route.name === "coords") {
        this.$router.replace({
          path: `/${event.zoom.toFixed(2)}/${event.latitude.toFixed(
            2
          )}/${event.longitude.toFixed(2)}`,
        });
      }
    },
    onBoundsChange(event) {
      this.setBbox(event);
    },
    onGeolocate(event) {
      const { longitude: lng, latitude: lat } = event.coords;
      this.geolocation = { lng, lat };
    },
    onTouch(event) {
      event.type === "touchstart" || event.type === "touchend"
        ? (this.touch = true)
        : (this.touch = false);
    },
  },
};
</script>

<style>
.ctrl-container {
  z-index: 10;
  position: absolute;
  top: calc(50% - 177px / 2);
  right: 0;
}
</style>
