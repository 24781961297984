import distance from '@turf/distance';

/**
 * Check if a coordinates is inside a bounding box
 *
 * @param {Object} bbox   The bounding box
 * @param {Object} coords The coordinates
 * @returns {Boolean}
 */
export function inBbox(bbox, coords) {
  if (
    coords[1] <= bbox.ne.lat &&
    coords[1] >= bbox.sw.lat &&
    coords[0] <= bbox.ne.lng &&
    coords[0] >= bbox.sw.lng
  ) {
    return true;
  } else {
    return false;
  }
}
/**
 * Sort a list of features by its distance to a point
 *
 * @param {Array} features The feature list to sort
 * @param {Array} coords   The coordinates a the reference point
 * @returns {Array}        The sorted list
 */
export function sortByDistance(features, coords) {
  return features.sort((a, b) => {
    if (distance(a.geometry.coordinates, coords) < distance(b.geometry.coordinates, coords))
      return -1;
    if (distance(b.geometry.coordinates, coords) < distance(a.geometry.coordinates, coords))
      return 1;
    return 0;
  });
}
