<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav>
    <div class="px-2 mx-auto bg-gray-100 shadow-md max-w-7xl sm:px-2">
      <div class="relative flex items-center justify-between h-12">
        <div>
          <router-link to="/">
            <div
              class="px-3 py-1 mr-6 font-mono text-white bg-green-500 border-2 border-green-500 border-solid rounded-md"
            >
              RV
            </div>
          </router-link>
        </div>
        <div>
          <router-link v-if="!isAuth" to="/signup">
            <button
              class="px-3 py-1 mr-6 text-white bg-green-500 border-2 border-green-500 border-solid rounded-md"
            >
              Sign Up
            </button>
          </router-link>
          <router-link v-if="!isAuth" to="/signin">
            <button
              class="px-3 py-1 text-green-500 border-2 border-green-500 border-solid rounded-md"
            >
              Sign In
            </button>
          </router-link>
          <button
            v-if="isAuth"
            @click="onLogout"
            class="px-3 py-1 text-white bg-green-500 border-2 border-green-500 border-solid rounded-md"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Navbar",
  computed: {
    ...mapState(["isAuth"]),
  },
  methods: {
    ...mapMutations(["loggedOut"]),
    onLogout() {
      this.loggedOut();
      this.$router.push("signin");
    },
  },
};
</script>

<style>
</style>