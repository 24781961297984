<template>
  <div class="flex items-center">
    <span class="flex items-center mr-2">
      <SpeedometerIcon class="w-4 mr-1" />
      {{this.hovered.speed || "N/A"}}kts
    </span>
    <span class="flex items-center">
      <AltimeterIcon class="w-4 mr-1" />
      {{this.hovered.altitude || "N/A"}}ft
    </span>
  </div>
</template>

<script>
import SpeedometerIcon from "../assets/icons/speedometer.svg";
import AltimeterIcon from "../assets/icons/altimeter.svg";
export default {
  name: "FlightTooltip",
  components: {
    SpeedometerIcon,
    AltimeterIcon,
  },
  props: {
    hovered: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>