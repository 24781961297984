<template>
  <MapboxSource
    :sourceId="id"
    type="geojson"
    promoteId="id"
    attribution="&copy; ADS-B Network"
    :data="flights"
  >
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="symbol"
      :filter="['all', ['!=', ['get', 'icon'], 'VSOL'], filter]"
      :layout="iconLayout"
      :paint="iconPaint"
    />
    <MapboxLayer
      layerId="ground"
      :sourceId="id"
      type="symbol"
      :minzoom="11"
      :filter="['all', ['==', ['get', 'icon'], 'VSOL'], filter]"
      :layout="iconLayout"
      :paint="iconPaint"
      :beforeId="id"
    />
    <MapboxLayer
      layerId="label-flight"
      :sourceId="id"
      type="symbol"
      :filter="['all', ['!=', ['get', 'icon'], 'VSOL'], filter]"
      :minzoom="8"
      :layout="labelLayout"
      :paint="labelPaint"
    />
    <MapboxLayer
      layerId="label-ground"
      :sourceId="id"
      type="symbol"
      :filter="['all', ['==', ['get', 'icon'], 'VSOL'], filter]"
      :minzoom="11"
      :layout="labelLayout"
      :paint="labelPaint"
    />
    <MapboxLayer
      layerId="pulse-flight"
      :sourceId="id"
      type="symbol"
      :minzoom="6"
      :filter="['in', ['get', 'id'], ['literal', pulse]]"
      :layout="{
        'icon-allow-overlap': true,
        'icon-image': 'pulse',
      }"
      :beforeId="id"
    />
    <FeatureState
      v-if="isSelected"
      :featureId="selected.id"
      :source="id"
      :state="{ select: true }"
    />
    <FeatureState
      v-if="isHovered"
      :featureId="hovered.id"
      :source="id"
      :state="{ hover: true }"
    />
  </MapboxSource>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "FlightLayer",
  computed: {
    ...mapState(["filter", "hovered", "selected", "pulse"]),
    ...mapGetters(["flights", "isSelected", "isHovered"]),
  },
  data() {
    return {
      id: "flights",
      layers: ["flights", "label", "ground"],
      iconLayout: {
        "icon-image": ["get", "icon"],
        "symbol-sort-key": ["to-number", ["get", "altitude"]],
        "icon-allow-overlap": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 0, 1, 24, 5],
        "icon-rotate": ["to-number", ["get", "bearing"]],
        "icon-pitch-alignment": "map",
        "icon-rotation-alignment": "map",
        "icon-keep-upright": false,
      },
      iconPaint: {
        "icon-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "select"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          "#E74C3C",
          ["==", ["get", "usage"], "Military"],
          "#454B1B",
          ["==", ["get", "usage"], "Private"],
          "#FFC000",
          "#3498db",
        ],
        "icon-halo-color": "#ffffff",
        "icon-halo-width": 1.5,
      },
      labelLayout: {
        "text-anchor": "top-left",
        "text-field": [
          "format",
          ["coalesce", ["get", "reg"], "N/A"],
          { "font-scale": 1 },
          "\n",
          ["coalesce", ["get", "callsign"], "N/A"],
          { "font-scale": 0.8 },
          "\n",
        ],
        "text-justify": "auto",
        "text-offset": [1, 1],
        "text-font": ["Open Sans Bold"],
        "text-size": 16,
        "text-pitch-alignment": "viewport",
      },
      labelPaint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 1.5,
      },
      loop: null,
    };
  },
  watch: {
    bbox: {
      async handler() {
        await this.fetchFlights();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchIcons();
    await this.fetchFlights();
    this.$emit("loaded");
    this.loop = setInterval(async () => {
      await this.fetchFlights();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.loop);
  },
  methods: {
    ...mapActions(["fetchIcons", "fetchFlights"]),
  },
};
</script>
