const URL = `https://mg21.adsbnetwork.com/zsrv`;

/**
 * Request all flights
 * @returns {Promise}
 */
export async function fetchFlights() {
  try {
    let res = await fetch(
      `${URL}/xenvolsrv.php?userid=betatesteur&serviceid=allicao`
    );
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Request the icon list
 * @returns {Promise}
 */
export async function fetchIcons() {
  try {
    let res = await fetch(
      `https://marceaudavid.github.io/radarvirtuel-icons/icons.json`
    );
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Request the path of a plane by it's icao
 * @param {string} icao
 * @returns {Promise}
 */
export async function fetchPath(icao) {
  try {
    let res = await fetch(
      `${URL}/xenvolsrv.php?userid=betatesteur&serviceid=icao&icao=${icao}`
    );
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
}

export async function fetchLastPath(icao, timestamp) {
  try {
    let res = await fetch(
      `${URL}/xenvolsrv.php?userid=betatesteur&serviceid=icao&icao=${icao}&ptm=${timestamp}`
    );
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Request all stations
 * @returns {Promise}
 */
export async function fetchStations() {
  try {
    let res = await fetch(`${URL}/Nlstsrv.php`);
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Request a stations's coverage zone
 * @param {string} id
 * @returns {Promise}
 */
export async function fetchZones(id) {
  try {
    let res = await fetch(
      `${URL}/NlstZrecep.php?serviceid=zonett&stid=${id.toLowerCase()}&typedt=geojson`
    );
    return res.json();
  } catch (error) {
    throw new Error(error);
  }
}
