import Vue from "vue";
import Vuex from "vuex";
import {
  fetchIcons,
  fetchFlights,
  fetchStations,
  fetchPath,
  fetchZones,
} from "../api/client";
import { normalize, iconSelector, isPositionValid } from "./helpers";
import { capitalizeFirstLetter } from "../utils/strings";
import { feature, featureCollection, lineString } from "@turf/helpers";
import { inBbox } from "../utils/geo";
// import simplify from '@turf/simplify';

Vue.use(Vuex);

const delay = 120;

export default new Vuex.Store({
  state: {
    camera: { longitude: 56, latitude: 23, zoom: 1.5, pitch: 0, bearing: 0 },
    screen: {},
    padding: { top: 100, left: 400, right: 40, bottom: 40 },
    bounds: null,
    bbox: null,
    expand: true,
    flights: {},
    stations: {},
    zones: {},
    path: {},
    icons: [],
    selected: {},
    hovered: {},
    pulse: [],
    filter: ["boolean", true],
    isModalOpen: false,
    showZone: false,
    token: localStorage.getItem("token") || null,
    isAuth: localStorage.getItem("token") !== null,
  },
  getters: {
    flights(state) {
      let flights = Object.values(state.flights);
      if (flights.length > 0) {
        return featureCollection(
          flights
            .filter(
              (flight) =>
                state.selected.id === flight.id ||
                inBbox(state.bbox, flight.coordinates) ||
                flight.coordinates.length > 0
            )
            .map((flight) => {
              let {
                coordinates,
                altitude,
                icon,
                bearing,
                reg,
                id,
                layer,
                callsign,
                usage,
              } = flight;
              return feature(
                { type: "Point", coordinates },
                { altitude, usage, icon, bearing, id, reg, layer, callsign }
              );
            })
        );
      } else {
        return featureCollection(flights);
      }
    },
    stations(state) {
      let stations = Object.values(state.stations);
      if (stations.length > 0) {
        return featureCollection(
          stations
            .filter(
              (station) =>
                station.status !== "KO" &&
                station.id !== "FOPEL" &&
                station.id !== "CDTC"
            )
            .map((station) => {
              let { coordinates, altitude, status, id, layer } = station;
              return feature(
                { type: "Point", coordinates },
                { altitude, status, id, layer }
              );
            })
        );
      } else {
        return featureCollection(stations);
      }
    },
    zones(state) {
      if (Object.keys(state.zones).length === 0) {
        return {
          type: "FeatureCollection",
          features: [],
        };
      } else {
        let zones = featureCollection(state.zones.features.slice(1));
        return zones;
        // simplify(zones, {
        //   tolerance: 1,
        //   highQuality: false,
        //   mutate: true,
        // });
      }
    },
    path(state) {
      let path = Object.values(state.path);
      if (path.length > 1) {
        let line = [];
        for (let i = 0; i < path.length - 1; i++) {
          let { coordinates, ...properties } = path[i];
          line = [
            ...line,
            lineString([coordinates, path[i + 1].coordinates], properties),
          ];
        }
        return featureCollection(line);
      } else {
        return featureCollection(path);
      }
    },
    positions(state) {
      let path = Object.values(state.path);
      if (path.length > 1) {
        return featureCollection(
          path.map((position) => {
            let { coordinates, altitude, timestamp } = position;
            return feature(
              { type: "Point", coordinates },
              { altitude, timestamp }
            );
          })
        );
      } else {
        return featureCollection(path);
      }
    },
    getSelected(state) {
      return (
        state.flights[state.selected.id] || state.stations[state.selected.id]
      );
    },
    isSelected(state) {
      return Object.values(state.selected).length > 0;
    },
    getMobileStationData(state) {
      return state.stations[state.flights[state.selected.id].callsign];
    },
    getHovered(state) {
      return (
        state.flights[state.hovered.id] || state.stations[state.hovered.id]
      );
    },
    isHovered(state) {
      return Object.values(state.hovered).length > 0;
    },
  },
  mutations: {
    loggedIn(state, payload) {
      state.token = payload.token;
      localStorage.setItem("token", payload.token);
      state.isAuth = true;
    },
    loggedOut(state) {
      state.token = null;
      localStorage.removeItem("token");
      state.isAuth = false;
    },
    setCamera(state, payload) {
      state.camera = { ...state.camera, ...payload };
    },
    setBounds(state, payload) {
      state.bounds = payload;
    },
    setBbox(state, payload) {
      state.bbox = payload;
    },
    setIcons(state, payload) {
      state.icons = payload;
    },
    setFlights(state, payload) {
      state.flights = normalize(
        Object.values({ ...state.flights, ...normalize(payload, "id") }).filter(
          (flight) => (Date.now() - flight.time * 1000) / 1000 < delay
        ),
        "id"
      );
    },
    setFilter(state, payload) {
      state.filter = payload;
    },
    setScreen(state, payload) {
      if (payload.width < 640) {
        state.padding = { top: 100, left: 40, right: 40, bottom: 300 };
        state.expand = false;
      } else {
        state.padding = { top: 100, left: 400, right: 40, bottom: 40 };
        state.expand = true;
      }
      state.screen = payload;
    },
    removeFilter(state) {
      state.filter = ["boolean", true];
    },
    setStations(state, payload) {
      state.stations = normalize(payload, "id");
    },
    updateStationById(state, { id, icao }) {
      let st = id.toUpperCase();
      let station = state.stations[st];
      if (station) {
        station.flights = [...new Set([...station.flights, icao])];
      }
    },
    filterStations(state) {
      state.stations = normalize(
        Object.values(state.stations).map((station) => {
          station.flights = station.flights.filter((icao) => {
            if (state.flights[icao] !== undefined) {
              return (Date.now() - state.flights[icao].time * 1000) / 1000 <
                delay
                ? true
                : false;
            } else {
              return false;
            }
          });
          return station;
        }),
        "id"
      );
    },
    setPath(state, payload) {
      state.path = normalize(payload, "timestamp");
    },
    setZones(state, payload) {
      state.zones = payload;
    },
    updatePath(state, { coordinates, timestamp, altitude }) {
      state.path = {
        ...state.path,
        [timestamp]: { coordinates, altitude, timestamp },
      };
    },
    setSelected(state, payload) {
      if (state.stations[payload.id] || state.flights[payload.id]) {
        state.selected = payload;
        let selected = state.stations[payload.id] || state.flights[payload.id];
        let [longitude, latitude] = selected.coordinates;
        state.camera = { ...state.camera, longitude, latitude };
      } else {
        state.isModalOpen = true;
      }
    },
    toggleModal(state, payload) {
      state.isModalOpen = payload;
    },
    toggleZone(state, payload) {
      state.showZone = payload;
    },
    removeSelected(state) {
      state.selected = {};
      state.path = {};
    },
    setHovered(state, payload) {
      if (state.flights[payload.id] || state.stations[payload.id]) {
        state.hovered = payload;
      }
    },
    removeHovered(state) {
      state.hovered = {};
    },
    setPulse(state, payload) {
      if (payload) {
        state.pulse = payload.stations || payload.flights;
      }
    },
    removePulse(state) {
      state.pulse = [];
    },
    toggleExpand(state, payload) {
      if (state.screen.width < 640) {
        state.expand = payload;
      } else {
        state.expand = true;
      }
    },
  },
  actions: {
    async fetchIcons({ commit }) {
      try {
        let res = await fetchIcons();
        commit("setIcons", res);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFlights({ state, commit }, bbox) {
      try {
        let res = await fetchFlights(bbox);
        let data = res
          .map((data) => {
            if (data.icao === state.selected.id && data.loc) {
              commit("updatePath", {
                coordinates: [data.loc.lng, data.loc.lat],
                altitude: data.alt || 0,
                timestamp: data.tm,
              });
            }
            return {
              id: data.icao.trim(),
              callsign: data.cs ? data.cs.trim() : undefined,
              reg: data.reg ? data.reg.trim() : undefined,
              prevCoords: state.flights[data.icao]
                ? state.flights[data.icao].coordinates
                : undefined,
              coordinates: data.loc ? [data.loc.lng, data.loc.lat] : [],
              prevAlt: state.flights[data.icao]
                ? state.flights[data.icao].altitude
                : undefined,
              altitude: data.alt,
              speed: data.spd,
              verticalSpeed: data.vrt,
              bearing: data.trk,
              squawk: data.swk,
              icon: iconSelector(data.icon, data.at, state.icons),
              category: data.at,
              opIcao: data.opicao,
              opName: data.opname,
              fromIcao: data.fricao,
              fromName: data.frname,
              toIcao: data.toicao,
              toName: data.toname,
              usage: data.cat ? capitalizeFirstLetter(data.cat) : undefined,
              typeIcao: data.itc ? data.itc.trim() : undefined,
              model: data.mod,
              lastTime: state.flights[data.icao]
                ? state.flights[data.icao].altitude
                : undefined,
              time: data.tm,
              display: data.affp || "O",
              layer: "flights",
              stations: data.st
                ? data.st.split(",").map((id) => {
                    commit("updateStationById", { id, icao: data.icao.trim() });
                    return id.toUpperCase();
                  })
                : [],
            };
          })
          .filter((data) => data.display === "O");
        commit("setFlights", data);
        commit("filterStations");
        if (
          state.flights[state.selected.id] === undefined &&
          state.stations[state.selected.id] === undefined &&
          Object.values(state.selected).length > 0
        ) {
          commit("removeSelected");
          commit("removePulse");
        }
        if (
          state.flights[state.hovered.id] === undefined &&
          state.stations[state.hovered.id] === undefined &&
          Object.values(state.hovered).length > 0
        ) {
          commit("removeHovered");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStations({ commit }) {
      try {
        let res = await fetchStations();
        let data = res.map((data) => {
          return {
            id:
              typeof data.idstation === "string"
                ? data.idstation.toUpperCase()
                : data.idstation,
            city: data.ville,
            country: data.pays,
            coordinates: [data.lng, data.lat],
            altitude: data.alt,
            status: data.status,
            url: data.urlimg || "",
            layer: "stations",
            flights: data.icaos ? data.icaos.split(",") : [],
          };
        });
        commit("setStations", data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchZones({ commit }, id) {
      try {
        commit("setZones", {});
        let data = await fetchZones(id);
        commit("setZones", data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPath({ commit }, id) {
      let res = await fetchPath(id);
      let data = [];
      if (res.length > 0 && res[0].positions) {
        let positions = res[0].positions;
        let lastValidIndex = 0;
        for (let i = 0; i < positions.length - 1; i++) {
          let within = isPositionValid(
            [positions[lastValidIndex].plng, positions[lastValidIndex].plat],
            [positions[i + 1].plng, positions[i + 1].plat]
          );
          if (within) {
            data.push({
              coordinates: [positions[i + 1].plng, positions[i + 1].plat],
              altitude: positions[i + 1].palt || 0,
              timestamp: positions[i + 1].ptm.sec,
            });
            lastValidIndex = i + 1;
          } else {
            lastValidIndex = i;
            i++;
          }
        }
      } else if (res[0] && res[0].loc && res[0].tm) {
        let position = res[0];
        data = [
          {
            coordinates: [position.loc.lng, position.loc.lat],
            altitude: position.alt || 0,
            timestamp: position.tm,
          },
        ];
      }
      commit("setPath", data);
    },
  },
});
